/* src/styles/responsive.css */

/* Base mobile-first styles */
:root {
  --safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);
  --navbar-height: 60px;
}

/* Mobile-first base styles */
html {
  -webkit-text-size-adjust: 100%;
  height: -webkit-fill-available;
}

body {
  min-height: -webkit-fill-available;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

/* Assessment form responsiveness */
.assessment-container {
  padding: 16px;
  max-width: 600px;
  margin: 0 auto;
}

.question-page {
  padding: 16px;
}

.assessment-input {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  font-size: 16px; /* Prevent zoom on iOS */
}

/* Dashboard responsiveness */
.dashboard-container {
  padding: 16px;
  padding-bottom: calc(var(--navbar-height) + var(--safe-area-inset-bottom));
}

.risk-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

/* Recommendations responsiveness */
.recommendations-container {
  padding: 16px;
  padding-bottom: calc(var(--navbar-height) + var(--safe-area-inset-bottom));
}

/* Profile page responsiveness */
.profile-container {
  padding: 16px;
  padding-bottom: calc(var(--navbar-height) + var(--safe-area-inset-bottom));
}

/* Navbar responsiveness */
.navbar {
  height: calc(var(--navbar-height) + var(--safe-area-inset-bottom));
  padding-bottom: var(--safe-area-inset-bottom);
}

/* Media Queries */
/* Tablets (portrait) */
@media (min-width: 768px) {
  .risk-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .assessment-container {
    padding: 24px;
  }
}

/* Tablets (landscape) and small desktops */
@media (min-width: 1024px) {
  .risk-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .assessment-container {
    padding: 32px;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .layout-container {
    min-height: -webkit-fill-available;
  }
}

/* Android specific fixes */
@supports not (-webkit-touch-callout: none) {
  .layout-container {
    min-height: 100vh;
  }
}

/* High contrast mode */
@media (prefers-contrast: high) {
  :root {
    --button-color: #0066cc;
    --text-color: #ffffff;
  }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #1a1a1a;
    --text-color: #ffffff;
  }
}