/* src/pages/Profile.css */
.profile-container {
  padding: 20px;
  background-color: #1a1a1a;
  color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.profile-header {
  text-align: center;
}

.profile-picture {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.profile-name {
  font-size: 1.5em;
  margin: 5px 0;
}

.edit-profile-button {
  padding: 10px 20px;
  background-color: #1e90ff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.edit-profile-button:hover {
  background-color: #0077cc;
}

.profile-section {
  width: 100%;
  padding: 15px;
  background-color: #2c2c2c;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-section p {
  margin: 0;
  font-size: 1.1em;
}

.retake-button {
  padding: 10px 20px;
  background-color: #1e90ff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.retake-button:hover {
  background-color: #0077cc;
}

.delete-button {
  padding: 10px 20px;
  background-color: #ff5555;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #cc4444;
}

.logout-text {
  color: #ff5555;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1em;
  text-align: center;
}
