/* src/pages/Assessment.css */
.assessment-container {
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.assessment-input,
.slider {
  margin: 10px 0;
  padding: 10px;
  font-size: 1em;
  width: 100%;
  border: 1px solid #444;
  border-radius: 5px;
}

.slider + span {
  display: inline-block;
  margin-left: 10px;
}

.assessment-button {
  min-height: 48px;  /* Standard mobile touch target size */
  padding: 12px 24px;
  margin: 10px;
  background-color: var(--button-color);
  color: var(--text-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: 600;
  min-width: 120px;  /* Ensure minimum width for touch targets */
}

.assessment-button:hover {
  background-color: #0077cc;
}

.gender-options label {
  display: block;
  margin: 5px 0;
}

.assessment-question-group {
  background-color: rgba(255, 255, 255, 0.05);
  margin: 20px 0;
  margin-bottom: 15px;
  padding: 15px;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;  
}

.assessment-question-group h3 {
  margin-bottom: 15px;
  color: #e0e0e0;
}

.assessment-question-group label {
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
}

.assessment-question-group input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.assessment-input,
select.assessment-input,
textarea.assessment-input {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #ffffff;
}

textarea.assessment-input {
  min-height: 100px;
  resize: vertical;
  margin: 8px 0;
  padding: 10px;
}
 
.stress-level {
  margin: 20px 0;
}

.stress-level .slider {
  width: 100%;
  margin: 10px 0;
}

.stress-level span {
  display: inline-block;
  margin-left: 10px;
  color: #e0e0e0;
}

/* Style for select dropdowns */
select.assessment-input option {
  background-color: #2c2c2c;
  color: #ffffff;
}

/* Hover states */
.assessment-input:hover,
select.assessment-input:hover {
  border-color: #666;
}

/* Focus states */
.assessment-input:focus,
select.assessment-input:focus {
  border-color: #0077cc;
  outline: none;
}

.button-group {
  display: flex;
  gap: 16px;  /* Increased gap between buttons */
  margin-top: 24px;
  justify-content: center;
}

.assessment-button.submit-button {
  background-color: #4CAF50;
  padding: 14px 28px;  /* Slightly larger padding for submit button */
  font-size: 1.2em;
}

.assessment-button.submit-button:hover {
  background-color: #45a049;
}

.assessment-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.required-field::after {
  content: ' *';
  color: #ff4d4d;
  margin-left: 2px;
}

.required-note {
  color: #ff4d4d;
  font-size: 0.9em;
  margin-bottom: 20px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95); /* Changed from 0.8 to 0.95 for darker background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-overlay);
}

.progress-container {
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.progress-bar {
  width: 100%;
  height: 8px;
  background-color: #2c2c2c;
  border-radius: 4px;
  overflow: hidden;
  margin: 20px 0;
}

.progress-fill {
  height: 100%;
  background-color: #1e90ff;
  transition: width 0.5s ease-out;
}

.progress-text {
  color: white;
  font-size: 1.1rem;
  margin: 10px 0;
  min-height: 1.5em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Added text shadow for better readability */
}