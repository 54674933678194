/* src/components/Navbar.css */
.navbar {
  z-index: var(--z-index-navbar);
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  display: flex;
  justify-content: space-around;
  padding: 8px 0;
  height: 60px;
}

.nav-item {
  color: #fff;
  text-decoration: none;
  font-size: 0.9em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.nav-item span {
  font-size: 12px;
}

.nav-item.active {
  color: #1e90ff;
  background-color: rgba(30, 144, 255, 0.1);
}

.nav-item:hover {
  color: #1e90ff;
}