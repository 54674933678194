/* src/pages/Login.css */
.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  color: var(--text-color);
  position: relative; /* For positioning the back button */
}

.login-form {
  background-color: #2c2c2c;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 320px;
  margin: 0 20px; /* Add horizontal margin */
}

.login-form h2 {
  font-size: 2.5em;  /* Match onboarding h1 */
  margin-bottom: 20px;
  color: #ffffff;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.back-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.login-title {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.login-error {
  color: #ff4d4d;
  background-color: #ffe6e6;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 0.9em;
}

.login-success {
  color: #4caf50;
  background-color: #e6ffe6;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 0.9em;
}

.login-input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #1a1a1a;
  color: var(--text-color);
}

.login-button {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 20px;
  background-color: var(--button-color);
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1em;  /* Match onboarding button font size */
  font-weight: bold;
}

.login-button:hover {
  background-color: #0077cc;
}

.login-footer {
  margin-top: 15px;
  font-size: 0.9em;
  color: var(--text-color);
}

.login-footer .signup-text {
  color: var(--text-color);
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}

.login-footer span {
  color: var(--button-color);
  cursor: pointer;
  text-decoration: underline;
}

.forgot-password-button {
  background: none;
  border: none;
  color: var(--text-color);
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 10px;
  font-weight: bold;
  padding: 0;
}
.forgot-password-button:hover {
  color: #0077cc;
}
