/* src/pages/Recommendations.css */
.recommendations-container {
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
  text-align: center;
  padding-bottom: calc(60px + 20px); /* navbar height (60px) + extra padding (20px) */
}

.recommendation-section {
  margin: 30px 0;
  padding: 20px;
  background-color: #2c2c2c;
  border-radius: 10px;
}

.recommendation-section h3 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.screening-item, .lifestyle-item {
  background-color: #383838;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
}

.screening-item h4, .lifestyle-item h4 {
  color: var(--button-color);
  margin-bottom: 10px;
}

.screening-frequency, .screening-age, .lifestyle-impact {
  color: #b0b0b0;
  font-size: 0.9rem;
  margin: 5px 0;
}

.screening-description, .lifestyle-recommendation {
  color: #e0e0e0;
  margin: 10px 0;
  line-height: 1.5;
}

.no-recommendations-message {
  text-align: center;
  margin-top: 50px;
  color: #888;
  font-size: 1.1rem;
}

/* For devices with safe-area-inset (like iPhone X and newer) */
@supports (padding: max(0px)) {
  .recommendations-container {
    padding-bottom: max(calc(60px + 20px), calc(60px + env(safe-area-inset-bottom)));
  }
}