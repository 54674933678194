/* src/pages/Onboarding.css */
.onboarding-container {
  height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-image {
  width: 55vw; /* The image will take 10% of the viewport width */
  max-width: 300px; /* Ensure it doesn't grow too large */
  height: auto;
  margin-bottom: 10px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.tagline {
  text-align: center; /* Centers the text horizontally */
  margin: 0 auto; /* Ensures proper horizontal centering */
  font-size: 1.2em;
  margin-bottom: 30px;
  color: #cccccc;
}

.onboarding-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.onboarding-btn {
  background-color: var(--button-color);
  color: var(--text-color);
  padding: 15px;
  border-radius: 5px;
  font-size: 1.1em;
  font-weight: bold;
  cursor: pointer;
  border: none;
  width: 250px;
}

.guest-text {
  color: #cccccc;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1.1em;
}

.guest-text:hover {
  color: var(--button-color);
}

.onboarding-btn:hover {
  background-color: #0077cc;
}

/* Image styling for the Onboarding page */
.onboarding-logo {
  width: 250px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space below the image */
}

.bottom-message {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  color: #cccccc;
}

.memorial-link {
  color: #ffffff;
  text-decoration: none;
  transition: all 0.2s ease;
}

.memorial-link:hover {
  color: var(--button-color);
  text-decoration: underline;
}