/* src/pages/WelcomeScreen.css */
.welcome-screen {
  min-height: 100vh;
  background-color: #1a1a1a;
  color: white;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #242424;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.logo-heart {
  font-size: 1.5rem;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.doctor-circle {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #2a2a2a;
  overflow: hidden;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
  object-position: center; /* Centers the image */
}

h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: white;
}

.message {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #e0e0e0;
  margin-bottom: 2rem;
  padding: 0 1rem;
}

.start-button {
  background-color: #ef4444;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.start-button:hover {
  background-color: #dc2626;
}

/* Responsive Design */
@media (max-width: 768px) {
  .content {
    padding: 1rem;
  }

  .doctor-circle {
    width: 250px;
    height: 250px;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.75rem;
  }

  .message {
    font-size: 1.rem;
  }

  .start-button {
    padding: 0.875rem 1.75rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .doctor-circle {
    width: 250px; /* Even larger for very small screens */
    height: 250px;
  }
}