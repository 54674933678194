/* styles/global.css */
:root {
  --background-color: #1a1a1a;
  --button-color: #1e90ff;
  --text-color: #ffffff;
  --z-index-base: 1;
  --z-index-modal: 100;
  --z-index-navbar: 1000;
  --z-index-overlay: 2000;
}

/* Base HTML/Body styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Combined body styles */
body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Inter', sans-serif;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Button styles */
button {
  background-color: var(--button-color);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* Mobile orientation lock */
@media screen and (max-width: 1023px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}