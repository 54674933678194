/* src/pages/Dashboard.css */
.dashboard-container {
  padding: 20px;
  background-color: #1a1a1a;
  color: #ffffff;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  /* Add more padding at bottom to account for navbar */
  padding-bottom: calc(60px + 20px); /* navbar height (60px) + extra padding (20px) */
}

.dashboard-title {
  font-size: 1.8em;
  margin-bottom: 20px;
  text-align: center;
}

.loading-message,
.error-message,
.dashboard-message {
  text-align: center;
  margin: 40px 0;
  font-size: 1.2em;
  color: #cccccc;
}

.error-message {
  color: #ff4d4d;
}

.dashboard-button {
  display: block;
  margin: 20px auto;
  padding: 12px 24px;
  background-color: #1e90ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dashboard-button:hover {
  background-color: #0077cc;
}

.risk-chart-container {
  background-color: #2c2c2c;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}

.risk-chart-container h3 {
  margin-bottom: 20px;
  color: #ffffff;
}

.risk-summary {
  background-color: #2c2c2c;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}

.risk-summary h3 {
  margin-bottom: 20px;
  color: #ffffff;
}

.risk-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.risk-card {
  background-color: #383838;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.risk-name {
  font-weight: 600;
  color: #ffffff;
}

.risk-percentage {
  color: #79bafc;
  font-size: 1.2em;
  font-weight: 600;
}

.risk-bar {
  height: 6px;
  background-color: #4a4a4a;
  border-radius: 3px;
  overflow: hidden;
}

.risk-bar-fill {
  height: 100%;
  background-color: #1e90ff;
  transition: width 1s ease-out;
}

.view-recommendations-button {
  display: block;
  margin: 30px auto;
  padding: 15px 30px;
  background-color: #22c55e;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.view-recommendations-button:hover {
  background-color: #16a34a;
}

@media (max-width: 768px) {
  .dashboard-container {
    padding: 15px;
  }

  .risk-chart-container,
  .risk-summary {
    padding: 15px;
  }

  .risk-grid {
    grid-template-columns: 1fr;
  }
}

/* For devices with safe-area-inset (like iPhone X and newer) */
@supports (padding: max(0px)) {
  .dashboard-container {
    padding-bottom: max(calc(60px + 20px), calc(60px + env(safe-area-inset-bottom)));
  }
}