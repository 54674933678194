/* src/pages/FactScreen.css */
.fact-screen {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.progress-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 95%; /* Increased from 90% to 95% */
  max-width: 2000px; /* Added max-width for very large screens */
  z-index: 10;
}

.progress-bar {
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: var(--text-color);
  transition: width 0.3s ease;
}

.fact-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  width: 100%;
}

.click-area {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  cursor: pointer;
  z-index: 5;
}

.click-area.left {
  left: 0;
}

.click-area.right {
  right: 0;
}

.fact-text {
  position: relative;
  z-index: 6;
}